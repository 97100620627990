<template>
  <div class="waitinglist">
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{
          lang_data == 1 ? "订单管理" : "Order Management"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "批量上传快递单号" : "Batch the flyer number"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mt20 mb20">
        <el-button
          size="small"
          type="primary"
          round
          :disabled="ids.length == 0"
          @click="showExpressModal_multi"
          >{{
            lang_data == 1 ? "批量上传快递单号" : "Add upload Tracking No."
          }}</el-button
        >
      </div>
      <div class="table-con flex-1">
        <el-table
          header-row-class-name="order-table"
          :data="tabledata"
          stripe
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="patient_name"
            :label="lang_data == 1 ? '患者' : 'Patient'"
            width="180"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                v-if="scope.row.order_type_show.value == 1"
                @click="$router.push('/orderDetails?id=' + scope.row.id)"
                >{{ scope.row.patient_name }}</el-link
              >
              <el-link
                v-else
                type="primary"
                @click="
                  $router.push(
                    `/afterSalesDetails?rebackid=${scope.row.id}&id=${scope.row.reback_id}`
                  )
                "
                >{{ scope.row.patient_name }}</el-link
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="factory_name"
            :label="lang_data == 1 ? '工厂' : 'Department Or Lab'"
          >
          </el-table-column>
          <el-table-column :label="lang_data == 1 ? '项目' : 'Item'">
            <template slot-scope="scope">
              {{
                scope.row.project_list
                  .map((item) => item.project_name)
                  .join("、")
              }}
            </template>
          </el-table-column>

          <el-table-column
            prop="order_type_show.label"
            width="100px"
            :label="lang_data == 1 ? '类型' : 'Type'"
          >
          </el-table-column>

          <el-table-column
            :label="lang_data == 1 ? '操作' : 'Action'"
            width="150px"
          >
            <template slot-scope="scope">
              <el-link type="primary" @click="showExpressModal(scope.row.id)">{{
                lang_data == 1 ? "上传快递单号" : "upload Tracking No."
              }}</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="total,prev, pager, next"
          @current-change="getlist"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          :total="page.totalCount"
        >
        </el-pagination>
      </div>
      <ExpressNumber
        v-if="expressNumberShow"
        :expressNumberShow="expressNumberShow"
        :orderId="orderId"
        :multiple="multiple"
        @ok-event="okEvent"
        @cancel-event="expressNumberShow = false"
      >
      </ExpressNumber>
    </div>
  </div>
</template>

<script>
import ExpressNumber from "@/components/expressNumber";
export default {
  name: "waitinglist",
  mounted() {
    this.getlist();
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      page: {
        totalCount: 0,
        pageSize: 10,
        currentPage: 1,
      },
      tabledata: [],
      ids: [],
      expressNumberShow: false,
      orderId: [],
      multiple: false,
    };
  },
  components: { ExpressNumber },
  methods: {
    handleSelectionChange(val) {
      this.ids = val;
    },

    getlist(current_page) {
      let params = {
        page_size: this.page.pageSize,
        current_page: current_page ? current_page : this.page.currentPage,
      };
      this.$axios
        .post("/orders/orders/getNoExpressOrderList", params)
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.tabledata = res.data.list;
            this.page.totalCount = res.data.total_count || 0;
            this.page.currentPage = res.data.current_page;
          }
        });
    },
    showExpressModal_multi() {
      this.multiple = true;
      this.orderId = this.ids.map((item) => {
        return item.id;
      });
      this.expressNumberShow = true;
    },
    showExpressModal(id) {
      this.multiple = false;
      this.orderId = [id];
      this.expressNumberShow = true;
    },
    ShowExpress(item) {
      this.current = item;
      this.expressShow = true;
    },
    okEvent() {
      this.expressNumberShow = false;
      this.getlist();
    },
  },
};
</script>
<style lang="scss" scoped>
.waitinglist {
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  .content {
    width: 1300px;
    margin: 0px auto;
    padding: 20px 0;
  }
}
.ContPadding {
  padding: 0px 84px;
  min-height: 250px;
  overflow: auto;
}
.modatitle {
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 110px;
  font-weight: bold;
}

.isDesign {
  border-radius: 8px;
  background: #009ae3;
  color: #fff;
  font-size: 12px;
  padding: 0px 3px;
}

.bottomCont {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
}
.page {
  text-align: right;
  margin: 20px 0;
}
</style>
